import { Box, Typography } from "@mui/material";
import { FormattedTypography } from "modules/picasso-ui/present/text/FormattedText";
import { FunctionComponent } from "react";
import { HeroProps } from "./HeroTypes";
import { useTheme } from "@emotion/react";
import { enhanceBreakpointValues } from "utils/muiUtils";

const styleDef = {
    rootCard: theme=>({
        background: theme.palette.gradient,
        borderRadius: '25px',
        padding: 'min(6vh, 64px) min(4vw, 64px)',
        [theme.breakpoints.down('sm')]: {
            padding: '24px',
        },
    }),
    label: theme=>({
        fontSize: '1.6em',
        color: theme.palette.secondary.main,
        fontWeight: 600,
        marginBottom: theme.spacing(2.2),
    }),
}

export const HeroHorizontal: FunctionComponent<HeroProps & {variant?: 'card', colorGradient?: string}> = ({title, titleStyle, titleShadow, subtitle, primaryContent, secondaryContent, variant, titleFontWeight, subtitleStyle, style, colorGradient, label}) => {

    const theme = useTheme()
    const rootStyle = [
        variant === 'card'? styleDef.rootCard:null,
        enhanceBreakpointValues(style, theme)
    ] as any

    return (
        <Box display="flex" pt="14vh" flexWrap="wrap" gap="36px" alignItems="center" sx={rootStyle}>
            <Box flex="5 1 500px" pb="2vh" sx={titleShadow?{textShadow: '2px 3px 33px rgb(0 0 0 / 36%)'}:null}>
                {label && <Typography component="div" sx={styleDef.label} className="label">{label}</Typography>}
                {/** @ts-ignore */}
                <FormattedTypography variant="h1" fontWeight={titleFontWeight||"700"} fontSize="3.4em" sx={[{color: '#fff'},titleStyle]}>{title}</FormattedTypography>
                {/** @ts-ignore */}
                <FormattedTypography mt={3} mb={3} fontSize="0.55em" color="#fff" sx={subtitleStyle} colorGradient={colorGradient} className="subtitle">{subtitle}</FormattedTypography>
                {primaryContent && <Box mt={3}>{primaryContent}</Box>}
            </Box>
            <Box flex="1 1 420px" position="relative">
                {secondaryContent}                
            </Box>
        </Box>
    )

}