import AppBarLanding from 'components/app/AppBarLanding';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { LandingHeadingPartial } from 'modules/landingPage/LandingPartials'
import { LandingPageValuePropositions } from './component/LandingPageValuePropositions';
import { LandingPersons } from 'components/layout/partials/LandingPersons';
import { LandingFooter } from 'modules/landingPage/component/LandingFooter';
import { LandingFooterNotice } from 'components/layout/partials/LandingFooterNotice';
import { LandingSaas } from 'modules/landingPage/component/saas/LandingSaas';
import { LandingPageValuePropositionsSaas } from './component/LandingPageValuePropositionsSaas';
import { LandingFooterSaas } from './component/saas/LandingFooterSaas';
import { LandingSecondarySectionTitle, LandingTypography } from './TypographyLanding';
import { LandingIncentiveCard } from './component/LandingIncentiveCard';
import { SlideUpAnimation } from 'modules/picasso-ui/present/effect/SlideUpAnimation';
import { AnimateWhenInViewport } from 'modules/picasso-ui/present/effect/AnimateWhenInViewport';
import { FeatureOverview } from './component/FeatureOverview';
import { ReadMoreLink } from './component/basics/ReadMoreLink';
import { LandingPageSnippet } from './component/LandingPageSnippet';
import { LandingCustomerVoices } from './component/socialProof/LandingCustomerVoices';
import { MainIncentiveLink } from './component/saas/MainIncentiveLink';
import { LandingFullWidthBanner } from './component/LandingFullWidthBanner';
import { LandingIncentiveInPage } from './component/LandingIncentiveInPage';
import { UseFromRootPageFeatureOverview } from './component/UseFromRootPageFeatureOverview';
import { Hero1 } from './component/heros/Hero1';
import { HeroCentered1 } from './component/heros/HeroCentered1';
import { Highlights } from 'modules/picasso-ui/present/specials/Highlights';
import { isHavingValue, isNullOrUndefined, notNullNotUndefined } from 'utils/objectUtils';
import { LandingCustomerQuotesSimple } from './component/socialProof/LandingCustomerQuotesSimple';
import { FooterSimple } from './component/basics/FooterSimple';
import dynamic from 'next/dynamic'
import { LandingImageV2 } from './component/images/LandingImageV2';
import { VsprioBadges } from 'modules/plinzip/component/VsprioBadges';
import { LandingCustomerVoicesCards } from './component/socialProof/LandingCustomerVoicesCards';
import { AppPricingPlans } from './component/pricing/AppPricingPlans';
import { LandingPageValuePropositionsImagesRound } from './component/LandingPageValuePropositionsImagesRound';
import { Section } from './component/basics/Sections';
import { HeroCenteredGradientUp } from './component/heros/HeroCenteredGradientUp';
import { LandingAccordeon } from './component/content/LandingAccordeon';
import { GermanyHostedBadge } from 'modules/picasso-ui/specials/GermanyHostedBadge';
import { LandingTabs } from './component/content/LandingTabs';
import { LandingCustomerVoiceLarge } from './component/socialProof/LandingCustomerVoiceLarge';
import { AppPlanComparisonView } from './component/pricing/AppPlanComparisonView';
import { LandingCustomerLogos } from './component/socialProof/LandingCustomerLogos';
import { HeroHorizontal } from './component/heros/HeroHorizontal';
import { LandingAvatarGroup } from './component/socialProof/LandingAvatarGroup';
import { LandingCards } from './component/cards/LandingCards';
import { Collapsed } from 'modules/picasso-ui/present/content/Collapsed';
import { CodeBlock } from 'modules/picasso-ui/specials/CodeBlock';
import { HeroCentered2 } from './component/heros/HeroCentered2';
import { LandingCard } from './component/cards/LandingCard';
import { Fragment } from 'react';
import { enhanceBreakpointValues } from 'utils/muiUtils';

const LandingPageValuePropositionsMainSaasStickySlider = dynamic(() =>
  import('modules/landingPage/component/saas/LandingPageValuePropositionsMainSaasStickySlider').then((mod) => mod.LandingPageValuePropositionsMainSaasStickySlider)
)
const VsprioMainIncentiveLink = dynamic(() =>
  import('modules/plinzip/component/landing/VsprioMainIncentiveLink').then((mod) => mod.VsprioMainIncentiveLink)
)
const PrioneerBadges = dynamic(() =>
  import('modules/plinzip/landing/PrioneerBadges').then((mod) => mod.PrioneerBadges)
)
const MsonicLandingFormDialogButton = dynamic(() =>
  import('modules/app-msonic/MsonicLandingFormDialogButton').then((mod) => mod.MsonicLandingFormDialogButton)
)
const MsonicReact = dynamic(() =>
  import('modules/app-msonic/MsonicReact').then((mod) => mod.MsonicReact)
)
const LandingForm = dynamic(() =>
  import(/* webpackChunkName: "lp-modules-form" */ 'modules/landingPage/LandingForm').then((mod) => mod.LandingForm)
)
const LandingIncentiveCollectMailSaas = dynamic(() =>
  import('modules/landingPage/component/saas/LandingIncentiveCollectMailSaas').then((mod) => mod.LandingIncentiveCollectMailSaas)
)
const LandingFormDialogButton = dynamic(() =>
  import('modules/landingPage/LandingFormDialogButton').then((mod) => mod.LandingFormDialogButton)
)
const LandingDialogButton = dynamic(() =>
    import('modules/landingPage/LandingDialogButton').then((mod) => mod.LandingDialogButton)
  )
const LandingFaqLight = dynamic(() =>
  import('modules/landingPage/component/faq/LandingFaqLight').then((mod) => mod.LandingFaqLight)
)
const LandingPageValuePropositionsMainSaas = dynamic(() =>
  import('modules/landingPage/component/saas/LandingPageValuePropositionsMainSaas').then((mod) => mod.LandingPageValuePropositionsMainSaas)
)
const LandingPageRaterDemo = dynamic(() =>
  import(/* webpackChunkName: "lp-demo-components" */ 'modules/plinzip/component/LandingPageDemoComponents').then((mod) => mod.LandingPageRaterDemo)
)
const LandingPageTeamStatisticsDemo = dynamic(() =>
  import(/* webpackChunkName: "lp-demo-components" */ 'modules/plinzip/component/LandingPageDemoComponents').then((mod) => mod.LandingPageTeamStatisticsDemo)
)
const PrioneerRankingsTemplatesPickerLandingPage = dynamic(() =>
  import('modules/plinzip/views/rankings/create/RankingsTemplatesPickerLandingPage').then((mod) => mod.RankingsTemplatesPickerLandingPage)
)
const LandingMobileApp = dynamic(() =>
  import('components/layout/partials/LandingMobileApp').then((mod) => mod.LandingMobileApp)
)
const LandingPageHowItWorks = dynamic(() =>
  import('./component/howItWorks/LandingPageHowItWorks').then((mod) => mod.LandingPageHowItWorks)
)
const LandingPageHowItWorksCards = dynamic(() =>
  import('./component/howItWorks/LandingPageHowItWorksCards').then((mod) => mod.LandingPageHowItWorksCards)
)
const LandingTestimonials = dynamic(() =>
  import('modules/landingPage/component/socialProof/LandingTestimonials').then((mod) => mod.LandingTestimonials)
)
const LandingCustomerStars = dynamic(() =>
  import('modules/landingPage/component/socialProof/LandingCustomerStars').then((mod) => mod.LandingCustomerStars)
)
const GetInvitationField = dynamic(() =>
  import('modules/landingPage/component/GetInvitationField').then((mod) => mod.GetInvitationField)
)
const TimelineSimple = dynamic(() =>
  import('modules/landingPage/TimelineSimple').then((mod) => mod.TimelineSimple)
)
const TimelineSaas = dynamic(() =>
  import('modules/landingPage/component/saas/TimelineSaas').then((mod) => mod.TimelineSaas)
)
const ResponsiveFlexBox = dynamic(() =>
    import('modules/picasso-ui/dataviews/grid/ResponsiveFlexBox').then((mod) => mod.ResponsiveFlexBox)
)
const LandingMarkdown = dynamic(() =>
    import('modules/landingPage/component/basics/LandingMarkdown').then((mod) => mod.LandingMarkdown)
)

const AppSubscribeFreePaidIncentiveView = dynamic(() =>
  import('modules/landingPage/component/pricing/AppSubscribeFreePaidIncentiveView').then((mod) => mod.AppSubscribeFreePaidIncentiveView)
)
const AppPlanDealCard = dynamic(() =>
  import('modules/landingPage/component/pricing/AppPlanDealCard').then((mod) => mod.AppPlanDealCard)
)

const LoginTesterQuickStart = dynamic(() =>
  import('modules/app-logintester/start/LoginTesterQuickStart').then((mod) => mod.LoginTesterQuickStart)
)

/**
 * Imagespawn
 */

const TagTemplates = dynamic(() =>
  import('modules/app-imagewhat/TagTemplates').then((mod) => mod.TagTemplates)
)
const ImagesCreateForUrlView = dynamic(() =>
  import('modules/app-imagewhat/ImagesCreateForUrlView').then((mod) => mod.ImagesCreateForUrlView)
)
const GetStartedView = dynamic(() =>
  import('modules/app-imagewhat/GetStartedView').then((mod) => mod.GetStartedView)
)
const ImagespawnLandingExamplesView = dynamic(() =>
  import('modules/app-imagewhat/LandingExamplesView').then((mod) => mod.LandingExamplesView)
)

const environment = process.env.NEXT_PUBLIC_ENVIRONMENT

/**
 * 
 * @param {*} item 
 *   required
 * @param {*} rootPage 
*   optional
 * @param {*} itemId 
 *  optional
 * @returns 
 */
export const renderItem = (item, rootPage, itemId, theme) => {
    if (item.visibleIf && !item.visibleIf.includes(environment)) {
        return null;
    }

    if (item.type === 'Divider') {
        return <Divider />
    }
    else if (item.type === 'LandingFooterSaas') {
        return <LandingFooterSaas {...item.props}/>
    }
    else if (item.type === 'FooterSimple') {
        return <FooterSimple {...item.props}/>
    }
    else if (item.type === 'appBar') {
        return <AppBarLanding {...item.props}/>;
    }
    else if (item.type === 'content') {
        const renderedProps = renderChildrenProps(item, true, false, theme) ?? {}
        const { content, style, ...effectiveProps} = {
            ...item.props,
            ...renderedProps
        }
        return <div id={item.props?.id}  {...effectiveProps} css={style}>{content}</div>;
    }
    else if (item.type === 'LandingMobileApp') {
        return <LandingMobileApp {...item.props}/>
    }
    else if (item.type === 'Hero1') {
        const renderedProps = renderChildrenProps(item, undefined, undefined, theme)
        return <Hero1 {...item.props} {...renderedProps} />
    }
    else if (item.type === 'HeroCenteredGradientUp') {
        const renderedProps = renderChildrenProps(item, undefined, undefined, theme)
        return <HeroCenteredGradientUp {...item.props} {...renderedProps} />
    }
    else if (item.type === 'HeroHorizontal') {
        const renderedProps = renderChildrenProps(item, undefined, undefined, theme)
        return <HeroHorizontal {...item.props} {...renderedProps} />
    }
    else if (item.type === 'LandingSaas') {
        return <LandingSaas {...item.props}/>
    }
    else if (item.type === 'TimelineSimple') {
        return <TimelineSimple {...item.props}/>
    }
    else if (item.type === 'LandingHeadingPartial') {
        return <LandingHeadingPartial {...item.props} />
    }
    else if (item.type === 'LandingPageValuePropositions') {
        return <LandingPageValuePropositions {...item.props} />
    }
    else if (item.type === 'LandingPageValuePropositionsSaas') {
        return <LandingPageValuePropositionsSaas {...item.props} />
    }
    else if (item.type === 'LandingPageValuePropositionsMainSaas') {
        const renderedProps = renderChildrenProps(item, undefined, undefined, theme)
        return <LandingPageValuePropositionsMainSaas {...item.props} {...renderedProps} />
    }
    else if (item.type === 'LandingPageValuePropositionsMainSaasStickySlider') {
        return <LandingPageValuePropositionsMainSaasStickySlider {...item.props} />
    }
    else if (item.type === 'LandingPageValuePropositionsImagesRound') {
        return <LandingPageValuePropositionsImagesRound {...item.props} />
    }
    else if (item.type === 'SectionHeading') {
        return <Box pb={5}>{renderContent(item.props.content, false, null, rootPage, undefined, undefined, theme)}</Box>
    }
    else if (item.type === 'Testimonials') {
        return <LandingTestimonials {...item.props} />
    }
    else if (item.type === 'Persons') {
        return <LandingPersons {...item.props} />
    }
    else if (item.type === 'Footer') {
        return <LandingFooter {...item.props} />
    }
    else if (item.type === 'FooterNotice') {
        return <LandingFooterNotice {...item.props} />
    }
    else if (item.type === 'Container') {
        return <Container id={item.props?.id}>{renderContent(item.props.content, false, null, rootPage, undefined, undefined, theme)}</Container>
    }
    else if (item.type === 'Section') {
        return <div id={item.props?.id} style={{paddingBottom: '100px'}}>{renderContent(item.props.content, false, null, rootPage, undefined, undefined, theme)}</div>
    }
    else if (item.type === 'MediumSection') {
        return <Section variant="medium" id={item.props?.id}>{renderContent(item.props.content, false,  null, rootPage, undefined, undefined, theme)}</Section>
    }
    else if (item.type === 'LargeSection') {
        return <Section variant="large" id={item.props?.id} >{renderContent(item.props.content, false,  null, rootPage, undefined, undefined, theme)}</Section>
    }
    else if (item.type === 'Margin') {
        return <div id={item.props?.id} style={{height: '100px'}}></div>
    }
    else if (item.type === 'SmallSection') {
        return <Section variant="small" id={item.props?.id}>{renderContent(item.props.content, false,  null, rootPage, undefined, undefined, theme)}</Section>
    }
    else if (item.type === 'LandingForm') {
        return <LandingForm {...item.props} />
    }
    else if (item.type === 'IncentiveCollectMailSaas') {
        return <LandingIncentiveCollectMailSaas {...item.props} />
    }
    else if (item.type === 'LandingDialogButton') {
        return <LandingDialogButton {...item.props}>{renderContent(item.props.content, false,  null, rootPage, undefined, undefined, theme)}</LandingDialogButton>
    }
    else if (item.type === 'LandingFormDialogButton') {
        return <LandingFormDialogButton {...item.props} />
    }
    else if (item.type === 'LandingSecondarySectionTitle') {
        const renderedProps = renderChildrenProps(item)
        return <LandingSecondarySectionTitle {...item.props} {...renderedProps} />
    }
    else if (item.type === 'LandingTypography') {
        return <Box textAlign="center"><LandingTypography {...item.props} /></Box>
    }
    else if (item.type === 'TimelineSaas') {
        return <TimelineSaas {...item.props} />
    }
    else if (item.type === 'LandingPageTeamStatisticsDemo') {
        return <LandingPageTeamStatisticsDemo />
    }
    else if (item.type === 'LandingPageRaterDemo') {
        return <LandingPageRaterDemo />
    }
    else if (item.type === 'LandingIncentiveCard') {
        return <LandingIncentiveCard {...item.props} mb={10}/>
    }
    else if (item.type === 'FeatureOverview') {
        return <FeatureOverview {...item.props} />
    }
    else if (item.type === 'ReadMoreLink') {
        return <ReadMoreLink {...item.props} />
    }
    else if (item.type === 'LandingAccordeon') {
        return <LandingAccordeon {...item.props} />
    }
    else if (item.type === 'LandingTabs') {
        const items = item.props?.items?.map(i=>{
            if (i.content && Array.isArray(i.content)) {
                return {...i, content: renderContent(i.content)}
            } else {
                return i
            }
        }) || null
        return <LandingTabs {...item.props} items={items}/>
    }
    else if (item.type === 'AppPricingPlans') {
        return <AppPricingPlans {...item.props} />
    }
    else if (item.type === 'AppPlanComparisonView') {
        // Immutable because no need to refresh on landing page
        return <AppPlanComparisonView {...item.props} immutable subscriptionFeaturesInfoViewProps={{includeWithoutTitle:false, sortFeaturesWithQuotaFirst: true}} />
    }
    else if (item.type === 'AppFreePaidIncentiveView') {
         // Immutable because no need to refresh on landing page
        return <AppSubscribeFreePaidIncentiveView {...item.props} immutable />
    }
    else if (item.type === 'AppPlanDealCard') {
       return <AppPlanDealCard {...item.props} />
   }
    else if (item.type === 'GetInvitationField') {
        return <GetInvitationField {...item.props} />
    }
    else if (item.type === 'Snippet') {
        return <LandingPageSnippet {...item.props} />
    }
    else if (item.type === 'LandingCustomerVoices') {
        return <LandingCustomerVoices {...item.props} />
    }
    else if (item.type === 'LandingCustomerVoicesCards') {
        return <LandingCustomerVoicesCards {...item.props} />
    }
    else if (item.type === 'LandingCustomerLogos') {
        return <LandingCustomerLogos {...item.props} />
    }
    else if (item.type === 'MainIncentiveLink') {
        return <MainIncentiveLink {...item.props}/>
    }
    else if (item.type === 'MainIncentiveLinkCentered') {
        return <Box textAlign="center"><MainIncentiveLink {...item.props}/></Box>
    }
    else if (rootPage && item.type === 'UseFromRootPageFeatureOverview') {
        return <UseFromRootPageFeatureOverview {...item.props} rootPage={rootPage}/>
    }
    else if (item.type === 'LandingFullWidthBanner') {
        return <LandingFullWidthBanner {...item.props} />
    }
    else if (item.type === 'LandingIncentiveInPage') {
        return <LandingIncentiveInPage {...item.props} />
    }
    else if (item.type === 'LandingFaq') {
        return <LandingFaqLight supportContact={rootPage?.supportContact} {...item.props} />
    }
    else if (item.type === 'LandingPageHowItWorks') {
        return <LandingPageHowItWorks {...item.props} />
    }
    else if (item.type === 'LandingPageHowItWorksCards') {
        return <LandingPageHowItWorksCards {...item.props} />
    }
    else if (item.type === 'Highlights') {
        return <Highlights {...item.props} />
    }
    else if (item.type === 'HeroCentered1') {
        return <HeroCentered1 {...item.props} />
    }
    else if (item.type === 'HeroCentered2') {
        const renderedProps = renderChildrenProps(item)
        return <HeroCentered2 {...item.props} {...renderedProps} />
    }
    else if (item.type === 'LandingCustomerStars') {
        return <LandingCustomerStars {...item.props} />
    }
    else if (item.type === 'LandingCustomerQuotesSimple') {
        return <LandingCustomerQuotesSimple {...item.props} />
    }
    else if (item.type === 'LandingCustomerVoiceLarge') {
        return <LandingCustomerVoiceLarge {...item.props} />
    }
    else if (item.type === 'LandingImageV2') {
        return <LandingImageV2 {...item.props} />
    }
    else if (item.type === 'VsprioMainIncentiveLink') {
        return <VsprioMainIncentiveLink {...item.props} />
    }
    else if (item.type === 'VsprioBadges') {
        return <VsprioBadges {...item.props} />
    }
    else if (item.type === 'GermanyHostedBadge') {
        return <GermanyHostedBadge {...item.props} />
    }
    else if (item.type === 'PrioneerRankingsTemplatesPickerLandingPage') {
        return <PrioneerRankingsTemplatesPickerLandingPage showLearnMoreLink {...item.props} locale={rootPage.localeEffective||null} />
    }
    else if (item.type === 'PrioneerBadges') {
        return <PrioneerBadges />
    }
    else if (item.type === 'MsonicLandingFormDialogButton') {
        return <MsonicLandingFormDialogButton {...item.props} />
    }
    else if (item.type === 'MsonicReact') {
        return <MsonicReact {...item.props} />
    }
    else if (item.type === 'LandingAvatarGroup') {
        return <LandingAvatarGroup {...item.props} />
    }
    else if (item.type === 'LandingCards') {
        return <LandingCards {...item.props} />
    }
    else if (item.type === 'LandingCard') {
        const renderedProps = renderChildrenProps(item, true, false)
        return <LandingCard {...item.props} {...renderedProps} />
    }
    else if (item.type === 'LandingMarkdown') {
        return <LandingMarkdown {...item.props}>{item.props?.children}</LandingMarkdown>
    }
    else if (item.type === 'ResponsiveFlexBox') {
        let { content, ...otherProps } = item.props??{}
        return <ResponsiveFlexBox {...otherProps}>{content && renderContent(content, false, null, null, false, undefined, theme)}</ResponsiveFlexBox>
    }
    else if (item.type === 'LoginTesterQuickStart') {
        return <LoginTesterQuickStart {...item.props} />
    }
    else if (item.type === 'CodeBlock') {
        return <CodeBlock {...item.props} />
    }
    else if (item.type === 'TagTemplates') {
        return <TagTemplates {...item.props} />
    }
    else if (item.type === 'ImagesCreateForUrlView') {
        return <ImagesCreateForUrlView {...item.props} />
    }
    else if (item.type === 'GetStartedView') {
        return <GetStartedView {...item.props} />
    }
    else if (item.type === 'ImagespawnLandingExamplesView') {
        return <ImagespawnLandingExamplesView {...item.props} />
    } else if (item.type === 'ReactNode') {
        return item.props.content
    }
    else {
        return <></>;
    }
}

const contentFields = ['startContent', 'centerContent', 'endContent', 'primaryContent', 'secondaryContent']

export const renderChildrenProps = (item, checkContentField, wrapperAllowed = true, theme) => {
    notNullNotUndefined(item)

    const rendered = {}

    const fList = [...contentFields]
    if (checkContentField) { fList.push('content') }

    for (const f of fList) {
        const fVal = item.props[f]
        const fEffective = fVal && Array.isArray(fVal) ? renderContent(fVal, false, null, null, wrapperAllowed, undefined, theme) : fVal
        if (fEffective) {
            rendered[f] = fEffective
        }
    }

    let items = null
    if (item.props?.items && item.props?.items.some(i=>isHavingValue(i.secondary?.content))) {
        items = []
        let i = 0;
        while (i < item.props.items.length) {
            const cItem = item.props.items[i]
            if (cItem.secondary?.content) {
                items.push({...cItem, secondary: {...cItem.secondary, content: renderContent(cItem.secondary?.content, false, null, null, wrapperAllowed, undefined, theme)}})
            } else {
                items.push(cItem)
            }
            i++;
        }
        if (items) { 
            rendered.items = items 
        }
    }
    
    return rendered
}

export const renderContent = (items, containerAllowed, maxWidth, rootPage, wrapperAllowed = true, itemIdsEnabled, theme) => {
    var elems = [];

/*     const start = Date.now(); */

    items?.forEach((v, i) => {
        const itemElem = renderItem(v, rootPage, null, theme)
        if (!itemElem) {
            return;
        }

        if (!wrapperAllowed) {
            elems.push(<Fragment key={i}>{itemElem}</Fragment>)
            return;
        }

        const hasCustomMaxWidth = isHavingValue(v.maxWidth)
        const wrapContainerActive = hasCustomMaxWidth || (containerAllowed !== false && v.type !== 'appBar' && v.type !== 'LandingFullWidthBanner' && v.container !== false && v.type !== 'SimpleLandingPageView')
        const maxWidthEffective = v.maxWidth || (v.type === 'LandingFooterSaas' ? 'lg' : maxWidth)

        let className;
        if (itemIdsEnabled || v.wrapperProps?.className) {
            className = [itemIdsEnabled?`child-idx-${i}-wrapper`:null, v.wrapperProps?.className].filter(v=>isHavingValue(v)).join(' ')
        }

        let style = v.wrapperProps?.style
        if (style && theme) {
            style = enhanceBreakpointValues(style, theme)
        }

        // paddingOnSmallScreen: use this if no container, because otherwise there is no padding
        elems.push(
            <ItemWrapper key={i} {...v.wrapperProps} style={style} className={className} component={v.type?.includes('Footer') && (isNullOrUndefined(v.props.component) || v.props.component === 'footer') ? 'footer':null}>
                {wrapContainer(v.collapse===true? <Collapsed stateless>{itemElem}</Collapsed>:itemElem, wrapContainerActive, maxWidthEffective, v.containerDisableGutters, v.containerProps)}
            </ItemWrapper>
        );
    });

/*     const end = Date.now();
    console.log(`Execution time (renderContent): ${end - start} ms`); */

    return elems;
}

export const LandingPageContentRenderer = ({items, containerAllowed}) => {
    return <>{renderContent(items??[], containerAllowed)}</>
}

const wrapContainer = (content, wrap, maxWidth, containerDisableGutters, containerProps) => {
    return wrap === true ? 
        <Container 
        maxWidth={maxWidth || 'lg'} 
        disableGutters={containerDisableGutters} 
        sx={[containerProps?.style||null,containerProps?.styleUpMd?theme=>({[theme.breakpoints.up('md')]:containerProps.styleUpMd}):null]}>{content}</Container>
        : content
}

const wrapAnimation = (content, wrap) => {
    return wrap === true ? 
        <AnimateWhenInViewport>{(animate)=><SlideUpAnimation animate={animate}><div>{content}</div></SlideUpAnimation>}</AnimateWhenInViewport>
        : content
}

function ItemWrapper({backgroundImage, backgroundVariant, children, style, backgroundColor, animate, id, component, paddingOnSmallScreen, className}) {

    const finalStyle = {...style};
    if(backgroundImage) {
        finalStyle.backgroundImage = "url('"+backgroundImage+"')";
    }
    if (backgroundColor) {
        finalStyle.backgroundColor = backgroundColor;
    }

    const css=[]
    if (backgroundVariant === 'contain') {
        css.push({
            backgroundPosition: 'top center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain',
        })
    } 
    else if (backgroundVariant === 'containSmaller') {
        css.push({
            backgroundPosition: 'top center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '80%',
        })
    }
    else {
        css.push({
            backgroundPosition: 'bottom 20px center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
        })
    }

    if (paddingOnSmallScreen) {
        css.push(theme=>({
            [theme.breakpoints.down('md')]: {
                ['& > div']: {
                    paddingLeft: theme.spacing(2),
                    paddingRight: theme.spacing(2),
                }
            }
        }))
    }

    css.push(finalStyle)

    let innerElem = null
    if (component === 'footer') {
        innerElem = <footer css={css} id={id} className={className}>{children}</footer>
    } else {
        innerElem = <div css={css} id={id} className={className}>{children}</div>
    }

    return (
        <>
            {wrapAnimation(innerElem, animate)}
        </>
    );
}